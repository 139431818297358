<template>
	<div class="dashboard">
		<div class="bg"></div>
		<div class="welcome">Welcome</div>
		<div class="title">欢迎进入{{ state.title }}</div>
	</div>
</template>
<script>
import { defineComponent, reactive } from 'vue'
export default defineComponent({
	setup() {
		const state = reactive({
			title: process.env.VUE_APP_TITLE,
		})
		return {
			state,
		}
	},
})
</script>
<style lang="less" scoped>
.dashboard {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	.bg {
		width: 580px;
		height: 412px;
		background: url('https://websitedn.yiautos.com/nrp4/main/bg_dashboard.png') no-repeat center
			center;
		background-size: 100%;
	}
	.welcome {
		font-family: PingFangSC-Semibold;
		font-weight: 600;
		font-size: 50px;
		color: #242b30;
		text-align: center;
	}
	.title {
		font-family: PingFangSC-Regular;
		font-weight: 400;
		font-size: 24px;
		color: #b0b0b0;
		text-align: center;
		line-height: 24px;
		padding-top: 10px;
	}
}
</style>
